import React, { useEffect, useRef, useState } from "react";
import { Modal, Table } from "react-bootstrap";
import DatePicker, { DateObject } from "react-multi-date-picker";
import { createWinery } from "../../services/User/UserService";
import { notifyError, notifyTopRight } from "../common/Toaster";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import { imageUpload } from "../../services/AuthService";
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
export default function AddWinery({ show, onHide, table, props }) {
  const vendorId = localStorage.getItem("vendorId");
  //Modal box
  let errorsObj = {
    name: "",
    address: "",
    overview: "",
    price: "",
    guests: "",
    category: "",
    date: "",
    amenities: "",
    photo: "",
  };
  const [formData, setFormData] = useState({
    name: "",
    address: "",
    latitude: "",
    longitude: "",
    overview: "",
    guests: "",
    price: "",
    photo: [],
    category: "",
    days: [],
    cancelledDates: [],
    amenities: [],
  });
  const [errors, setErrors] = useState(errorsObj);
  const [newText, setNewText] = useState("");
  const [reference, setReference] = useState([]);
  const [currentEditIndex, setCurrentEditIndex] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const format = "YYYY-MM-DD";
  const [dates, setDates] = useState([
    new DateObject().set({ day: null, format }),
  ]);
  const formattedDates = dates.map((date, index) => {
    return date.format();
  });
  const [selectedDuration, setSelectedDuration] = useState("");

  console.log(formData?.selectedDays); // This will log an array of formatted dates (MM/DD/YYYY)

  let zipCode = "";
  let city = "";

  const handleAddText = (e) => {
    e.preventDefault();
    const newArr = reference;
    if (newText.trim() && newText != "") {
      newArr.push(newText.trim());
    }
    setReference(newArr);
    setNewText("");
  };
  const handleUpdateText = () => {
    // ...
    const updatedReference = [...reference];
    updatedReference[currentEditIndex] = newText;
    setReference(updatedReference);
    setNewText("");
    setIsEdit(false);
  };
  const handleEdit = (index, item) => {
    // Set newText state to the item being edited
    setNewText(item);
    // Set the currentEditIndex state to the index of the item being edited
    setCurrentEditIndex(index);
    // Set isEdit state to true to indicate that we're in edit mode
    setIsEdit(true);
  };
  const handleDelete = (index) => {
    const newReferences = [...reference];
    newReferences.splice(index, 1);
    setReference(newReferences);
  };

  const handleInputChange = (e) => {
    const selectedFiles = e.target.files;
    // Check if number of selected files is within the allowed range
    if (selectedFiles.length < 5) {
      setErrors({ ...errors, photo: "Please select 5 images" });
      return;
    } else if (selectedFiles.length > 5) {
      setErrors({ ...errors, photo: "Please select 5 images only" });
      return;
    }

    const uploadPromises = [];

    // Loop through each selected file
    for (let i = 0; i < selectedFiles.length; i++) {
      const selectedFile = selectedFiles[i];
      console.log(selectedFile, "image before upload");
      // Upload each file and store the promise
      if (selectedFile) {
        const uploadPromise = imageUpload(selectedFile, vendorId)
          .then((response) => {
            console.log(response, "image location");
            return response.data?.data?.photo; // Return URL
          })
          .catch((error) => {
            console.log(error, "image upload error");
            return null; // Return null if error
          });
        uploadPromises.push(uploadPromise);
      }
    }

    // Wait for all promises to resolve
    Promise.all(uploadPromises).then((photoUrls) => {
      // Update the state with all photo URLs
      setFormData({
        ...formData,
        photo: photoUrls.filter((url) => url !== null), // Remove null values
      });
      setErrors({ ...errors, photo: "" });
    });
  };
  const handleAddress = (place) => {
    console.log(place, "address list");

    geocodeByAddress(place.label)
      .then((results) => {
        console.log(results, "address list results");

        if (results && results.length > 0) {
          const addressComponents = results[0]?.address_components || [];

          const city =
            addressComponents.find(
              (component) =>
                component.types.includes("locality") ||
                component.types.includes("administrative_area_level_2")
            )?.long_name || "";

          const zipCode =
            addressComponents.find((component) =>
              component.types.includes("postal_code")
            )?.long_name || "";

          return getLatLng(results[0]);
        } else {
          throw new Error("No results found for the provided address.");
        }
      })
      .then((res) => {
        console.log(res, "lat lng res");
        setFormData({
          ...formData,
          address: place?.label,
          latitude: res.lat,
          longitude: res.lng,
        });
        setErrors({ ...errors, address: "" });
      })
      .catch((error) => {
        console.error("Error getting address details:", error);
      });
  };

  const [formDataTime, setFormDataTime] = useState({
    timeSlots: [], // Unified time slots array
    startTime: "",
    endTime: "",
  });
  // const handleAddTime = () => {
  //   if (formDataTime.startTime && formDataTime.endTime) {
  //     setFormDataTime((prevState) => ({
  //       ...prevState,
  //       timeSlots: [
  //         ...prevState.timeSlots,
  //         {
  //           startTime: formDataTime.startTime,
  //           endTime: formDataTime.endTime,
  //         },
  //       ],
  //       startTime: "",
  //       endTime: "",
  //     }));
  //   }
  // };
  const handleAddTime = () => {
    const { startTime, endTime } = formDataTime;
    const duration = parseInt(selectedDuration, 10); // Assuming selectedDuration is stored in state

    if (startTime && endTime && duration) {
      const start = new Date(`1970-01-01T${startTime}:00`); // Use a dummy date
      const end = new Date(`1970-01-01T${endTime}:00`);
      const timeSlots = [];

      while (start < end) {
        const nextStart = new Date(start.getTime() + duration * 60000); // Increment by duration
        if (nextStart > end) break;

        timeSlots.push({
          startTime: start.toTimeString().substr(0, 5),
          endTime: nextStart.toTimeString().substr(0, 5),
        });

        start.setTime(nextStart.getTime()); // Move start to next slot
      }

      setFormDataTime((prevState) => ({
        ...prevState,
        timeSlots,
        startTime: "",
        endTime: "",
      }));
    }
  };

  const handleDeleteTime = (index) => {
    const updatedTimeSlots = [...formDataTime.timeSlots];
    updatedTimeSlots.splice(index, 1);
    setFormDataTime({ ...formDataTime, timeSlots: updatedTimeSlots });
  };
  // console.log(formData, "formData");
  async function onPostData(e) {
    // console.log("ffffffff");
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    if (formData.photo.length === 0) {
      errorObj.photo = "This field is required";
      error = true;
    }
    if (formData.name.trim() === "") {
      errorObj.name = "This field is required";
      error = true;
    }
    if (formData.address.trim() === "") {
      errorObj.address = "This field is required";
      error = true;
    }
    if (formData.guests.trim() === "") {
      errorObj.guests = "This field is required";
      error = true;
    }
    if (formData.price.trim() === "") {
      errorObj.price = "This field is required";
      error = true;
    }
    if (formData.category === "") {
      errorObj.category = "This field is required";
      error = true;
    }
    if (formData.overview.trim() === "") {
      errorObj.overview = "This field is required";
      error = true;
    }
    if (reference.length === 0) {
      errorObj.amenities = "This field is required";
      error = true;
    }

    setErrors(errorObj);
    if (error) {
      return;
    }
    try {
      const response = await createWinery({
        ...formData,
        amenities: reference,
        cancelledDates: formattedDates,
        openingHours: formDataTime.timeSlots,
      });
      onHide();
      table();
      notifyTopRight(response.data?.message);
      console.log(response.data.data.users);
    } catch (error) {
      notifyError(error.response?.data?.message);
      console.log(error, "error");
    } finally {
      //   setLoader(false);
    }
  }
  function generateTimeOptions() {
    const times = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 5) {
        const formattedHour = String(hour).padStart(2, "0");
        const formattedMinute = String(minute).padStart(2, "0");
        times.push(`${formattedHour}:${formattedMinute}`);
      }
    }
    return times;
  }

  const timeOptions = generateTimeOptions();

  function TimeInput({ label, value, onChange }) {
    return (
      <div className="w-100">
        <label>{label}</label>
        <input
          type="time"
          className="form-control "
          list="time-options"
          value={value}
          onChange={onChange}
        />
        <datalist id="time-options">
          {timeOptions?.map((time, index) => (
            <option key={index} value={time}></option>
          ))}
        </datalist>
      </div>
    );
  }
  const handleDaysChange = (days) => {
    setFormData((prevState) => ({
      ...prevState,
      days,
    }));
  };
  useEffect(() => {
    const handleGlobalClick = (event) => {
      if (event.target.innerText === "Select/Deselect All") {
        event.preventDefault();
      }
    };

    window.addEventListener("click", handleGlobalClick);
    return () => {
      window.removeEventListener("click", handleGlobalClick);
    };
  }, []);
  useEffect(() => {
    handleAddTime();
  }, [formDataTime?.endTime]);
  return (
    <Modal className="modal fade" show={show} centered onHide={onHide}>
      <div className="" role="document">
        <div className="">
          <form>
            <div className="modal-header">
              <h4 className="modal-title fs-20">Add Details</h4>
            </div>
            <div className="modal-body">
              <i className="flaticon-cancel-12 close" data-dismiss="modal"></i>
              <div className="add-contact-box">
                <div className="add-contact-content">
                  <div className="field-wrapper">
                    <div className="form-group mb-3 w-50">
                      <label className="text-black font-w500">Images</label>
                      <div className="contact-name">
                        <input
                          type="file"
                          accept=".png,.jpg,.jpeg"
                          multiple
                          className="form-control"
                          name="name"
                          onChange={handleInputChange}
                        />
                        <span className="validation-text"></span>
                        {errors.photo && (
                          <div className="text-danger fs-12">
                            {errors.photo}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="form-group mb-3 w-50">
                      <label className="text-black font-w500">Name</label>
                      <div className="contact-name">
                        <input
                          type="text"
                          className="form-control"
                          name="name"
                          value={formData.name}
                          onChange={(e) => {
                            setFormData({ ...formData, name: e.target.value });
                            setErrors({ ...errors, name: "" });
                          }}
                        />
                        <span className="validation-text"></span>
                        {errors.name && (
                          <div className="text-danger fs-12">{errors.name}</div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="field-wrapper">
                    <div className="form-group mb-3">
                      <label className="text-black font-w500">Max amount of Guests per session</label>
                      <div className="contact-name">
                        <input
                          type="number"
                          className="form-control"
                          autocomplete="off"
                          name="department"
                          value={formData.guests}
                          min={0}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              guests: e.target.value,
                            });
                            setErrors({ ...errors, guests: "" });
                          }}
                        />
                        <span className="validation-text"></span>
                        {errors.guests && (
                          <div className="text-danger fs-12">
                            {errors.guests}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="form-group mb-3">
                      <label className="text-black font-w500">Price</label>
                      <div className="contact-name">
                        <input
                          type="number"
                          className="form-control"
                          autocomplete="off"
                          name="department"
                          value={formData.price}
                          min={0}
                          onChange={(e) => {
                            setFormData({ ...formData, price: e.target.value });
                            setErrors({ ...errors, price: "" });
                          }}
                        />
                        <span className="validation-text"></span>
                        {errors.price && (
                          <div className="text-danger fs-12">
                            {errors.price}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="field-wrapper">
                    <div className="form-group mb-3 w-100">
                      <label className="text-black font-w500">Address</label>
                      <div className="contact-name">
                        <GooglePlacesAutocomplete
                          apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                          selectProps={{
                            placeholder: "Enter Address",
                            inputValue: inputValue,
                            onInputChange: (value) => setInputValue(value),
                            onChange: handleAddress,
                            noOptionsMessage: () =>
                              inputValue ? "No options" : null, // Hide "No options" when input is empty
                            className: "form-control",
                          }}
                          autocompletionRequest={{
                            input: inputValue,
                            componentRestrictions: { country: ["CY", "GR"] }, // Restrict to Greece
                          }}
                        />
                        {errors.address && (
                          <div className="text-danger fs-12">
                            {errors.address}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="form-group mb-3 w-100">
                      <label className="text-black font-w500">Category</label>
                      <div className="contact-name">
                        <select
                          className="form-control"
                          value={formData.category}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              category: e.target.value,
                            });
                            setErrors({ ...errors, category: "" });
                          }}
                        >
                          <option hidden>Select</option>
                          <option>Wine Tasting</option>
                          <option>Wine Tours</option>
                          <option>Others</option>
                        </select>

                        <span className="validation-text"></span>
                        {errors.category && (
                          <div className="text-danger fs-12">
                            {errors.category}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <label className="text-black font-w500">Days</label>
                    <div id="multiselect">
                      <DropdownMultiselect
                        className="form-control"
                        options={[
                          "Sunday",
                          "Monday",
                          "Tuesday",
                          "Wednesday",
                          "Thursday",
                          "Friday",
                          "Saturday",
                        ]}
                        name="days"
                        selected={formData.days}
                        handleOnChange={handleDaysChange}
                        placeholder="Select days"
                      />
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <label className="text-black font-w500">
                      Duration (mins)
                    </label>
                    <div className="contact-name">
                      <select
                        className="form-control"
                        value={selectedDuration}
                        onChange={(e) => setSelectedDuration(e.target.value)}
                      >
                        {[15, 30, 45, 60, 75, 90, 105, 120].map((duration) => (
                          <option key={duration} value={duration}>
                            {duration}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <label className="text-black font-w500">Time Slots</label>
                    {formDataTime?.timeSlots?.length > 0 && (
                      <div className="ml-1 mb-2">
                        <ul>
                          {formDataTime.timeSlots.map((item, index) => (
                            <div
                              className="d-flex justify-content-between fs-14"
                              key={index}
                            >
                              <li>
                                {item.startTime} - {item.endTime}
                              </li>
                              <div>
                                <i
                                  className="flaticon-381-trash-1 pointer ml-2"
                                  onClick={() => handleDeleteTime(index)}
                                ></i>
                              </div>
                            </div>
                          ))}
                        </ul>
                      </div>
                    )}
                    <div
                      className="contact-name d-flex justify-content-between align-items-end"
                      style={{ gap: "10px" }}
                    >
                      <TimeInput
                        label="Start Time"
                        value={formDataTime.startTime}
                        onChange={(e) =>
                          setFormDataTime({
                            ...formDataTime,
                            startTime: e.target.value,
                          })
                        }
                      />
                      <TimeInput
                        label="End Time"
                        value={formDataTime.endTime}
                        onChange={(e) =>
                          setFormDataTime({
                            ...formDataTime,
                            endTime: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>

                  <div className="form-group mb-3">
                    <label className="text-black font-w500">
                      Cancelled Dates
                    </label>
                    <div className="contact-name">
                      <DatePicker
                        value={dates}
                        onChange={setDates}
                        multiple
                        sort
                        format={format}
                        calendarPosition="bottom-center"
                        // plugins={[<DatePanel />]}
                        minDate={new Date()}
                      />

                      <span className="validation-text"></span>
                      {errors.date && (
                        <div className="text-danger fs-12">{errors.date}</div>
                      )}
                    </div>
                  </div>

                  <div className="form-group mb-3">
                    <label className="text-black font-w500">Amenities</label>
                    {reference.length > 0 && (
                      <div className=" mb-2">
                        <ul>
                          {reference.map((item, index) => (
                            <div
                              className="d-flex justify-content-between fs-14"
                              key={index}
                            >
                              <li style={{ maxWidth: "400px" }}>{item} </li>
                              <div>
                                <i
                                  className="flaticon-381-edit-1 pointer"
                                  onClick={() => handleEdit(index, item)}
                                ></i>
                                <i
                                  className="flaticon-381-trash-1 pointer ml-2"
                                  onClick={() => handleDelete(index)}
                                ></i>
                              </div>
                            </div>
                          ))}
                        </ul>
                      </div>
                    )}
                    <div className="contact-name d-flex">
                      <input
                        type="text"
                        className="form-control mr-2"
                        value={newText}
                        onChange={(e) => {
                          setNewText(e.target.value);

                          setErrors({ ...errors, amenities: "" });
                        }}
                      />
                      <button
                        type="button"
                        className="btn btn-primary btn-color py-1 px-3"
                        onClick={isEdit ? handleUpdateText : handleAddText}
                      >
                        {isEdit ? "Update" : "Add"}
                      </button>
                    </div>
                    {errors.amenities && (
                      <div className="text-danger fs-12">
                        {errors.amenities}
                      </div>
                    )}
                  </div>

                  <div className="form-group mb-0">
                    <label className="text-black font-w500">Overview</label>
                    <div className="contact-name">
                      <textarea
                        type="text"
                        rows={4}
                        className="form-control"
                        autocomplete="off"
                        name="department"
                        value={formData.overview}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            overview: e.target.value,
                          });
                          setErrors({ ...errors, overview: "" });
                        }}
                      />
                      <span className="validation-text"></span>
                      {errors.overview && (
                        <div className="text-danger fs-12">
                          {errors.overview}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary fs-14 py-2 px-3"
                onClick={onPostData}
              >
                Submit
              </button>
              <button
                type="button"
                onClick={() => onHide()}
                className="btn btn-secondary fs-14 py-2 px-3"
              >
                {" "}
                <i className="flaticon-delete-1"></i> Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
}
