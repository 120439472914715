import axiosInstance from "../AxiosInstance";
import {
  BLOCK_USERS,
  BOOK_WINERY,
  DELETE_WINERY,
  EDIT_WINERY,
  GET_POLICY,
  GET_SLOT,
  GET_WINERY,
  POST_WINERY,
} from "./UserApiEndPoints";
export function getWinery(currentPage, limit, search) {
  return axiosInstance.get(GET_WINERY + `?page=${currentPage}&limit=${limit}`);
}
export function getTimeSlots(wineryId, date) {
  const params = {
    wineryId: wineryId,
    date: date,
  };

  return axiosInstance.get(GET_SLOT, { params });
}
export function bookWinery(postData) {

  return axiosInstance.post(BOOK_WINERY, postData);
}
export function blockUser(id, actionType) {
  const postData = { userId: id, isBlocked: actionType };
  return axiosInstance.put(BLOCK_USERS, postData);
}

export function createWinery(formData) {
  console.log(formData, "formData");
  const postData = {};

  return axiosInstance.post(POST_WINERY, formData);
}
export function deleteWinery(id) {
  return axiosInstance.delete(DELETE_WINERY + `?wineryId=${id}`);
}
export function editWinery(formData) {
  console.log(formData, "formData");
 

  return axiosInstance.put(EDIT_WINERY, formData);
}

export function getPolicyTerms() {


  return axiosInstance.get(GET_POLICY);
}

