import React from 'react'
import Lottie from "lottie-react";
import animationData from "../../images/spinner.json";

export default function Loader() {
  return (

    <div className=' full-page-loader ' >
      <Lottie
        animationData={animationData}
        
      />
    </div>
  );
}