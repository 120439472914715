import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import cancelIcon from "../../images/svg/remove 1.svg";
// import closeIcon from "../../../assets/svg/closeIcon.svg";

import parse from "html-react-parser";
import ButtonLoader from "../common/ButtonLoader";
import { getPolicyTerms } from "../../services/User/UserService";
import { notifyError, notifyTopRight } from "../common/Toaster";
import { cancelBooking } from "../../services/Bookings/BookingService";
export default function CancelBooking({ show, bookingId, table, onHide }) {
  const [loader, setLoader] = useState(false);
  const [loader2, setLoader2] = useState(false);

  const [policy, setPolicy] = useState(null);
  ///function to cancel booking ///
  const onCancel = async () => {
    setLoader(true);
    try {
      const response = await cancelBooking(bookingId);
      table();
      notifyTopRight("Canceled Successfully.");
      onHide();
    } catch (error) {
      notifyError(error?.response?.data?.message);
      console.log(error, "error");
    } finally {
      setLoader(false);
    }
  };

  return (
    <Modal show={show} centered onHide={onHide}>
      <Modal.Header className="border-0" closeButton>
        <Modal.Title>Cancel Booking</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4 text-center">
        <img src={cancelIcon} />
        <h3 className="heading mb-3 mt-3 text-theme">Cancel Booking?</h3>
        <p className="light-text mb-4">
          Are you sure you want to cancel booking?
        </p>
        <div className="text-left">
          <h4 className="fs-18 mb-3 text-theme">Cancellation Policy</h4>
         <a href="/cancellation-policy" target="_blank">View Policy</a>
        </div>
      </Modal.Body>
      <Modal.Footer className="justify-content-center border-top-0  pt-0 p-4">
        <button
          // type="button"
          className="w-100 btn btn-primary"
          onClick={()=>onCancel()}
          disabled={loader}
        >
          {loader ? (
            <>
              <ButtonLoader /> Cancel Booking
            </>
          ) : (
            "Cancel Now"
          )}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
