import { parseISO } from "date-fns";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { bookWinery, getTimeSlots } from "../../services/User/UserService";
import { notifyError, notifyTopRight } from "../common/Toaster";
import TimeSlot from "../components/TimeSlot";

export default function BookWinery({ show, wineryId, onHide, table }) {
  //Modal box
  let errorsObj = {
    guests: "",
    date: "",
  };

  const format = "YYYY-MM-DD";

  const [formData, setFormData] = useState({
    guests: "",
    date: "",
  });
  console.log(formData, "formData");
  const [loader, setLoader] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [slots, setSlots] = useState([]);
  const [errors, setErrors] = useState(errorsObj);

  const getSlots = async () => {
    setLoader(true);
    const formattedDate = moment(formData?.date).format("YYYY-MM-DD");
    try {
      const response = await getTimeSlots(wineryId?._id, formattedDate);
      setSlots(response.data?.data);
      console.log(response.data?.data);
    } catch (error) {
      notifyError(error?.response?.data?.message);
    } finally {
      setLoader(false);
    }
  };
  async function onPostData(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };

    if (!formData.date) {
      errorObj.date = "Date is required";
      error = true;
    }

    if (!selectedSlot) {
      errorObj.slots = "Please select a time slot";
      error = true;
    }

    if (!formData.guests || formData.guests <= 0) {
      errorObj.guests = "Number of guests is required and must be greater than 0";
      error = true;
    } else if (selectedSlot && formData.guests > selectedSlot.availableGuests) {
      errorObj.guests = `Maximum ${selectedSlot.availableGuests} guests allowed for this slot`;
      error = true;
    }

    
    console.log(errorObj, "errorObj");
    setErrors(errorObj);
    if (error) {
      return;
    }
    try {
      const response = await bookWinery({
        ...formData,
        wineryId: wineryId?._id,
        startTime: selectedSlot.startTime,
        endTime: selectedSlot.endTime,
      });
      onHide();
      table();
      notifyTopRight(response.data?.message);
      console.log(response.data.data.users);
    } catch (error) {
      notifyError(error.response?.data?.message);
      console.log(error, "error");
    } finally {
      //   setLoader(false);
    }
  }

  useEffect(() => {
    // Only set the date if upcomingSlotsAvailable exists
    if (wineryId?.upcomingSlotsAvailable?.date) {
      setFormData({ date: parseISO(wineryId.upcomingSlotsAvailable.date) });
    } else {
      // Set to current date or clear the date
      setFormData({ date: new Date() });
    }
  }, [wineryId?.upcomingSlotsAvailable?.date]);

  useEffect(() => {
    if (formData?.date) {
      getSlots();
    }
  }, [formData?.date]);
  return (
    <Modal className="modal fade" show={show} centered onHide={onHide}>
      <div className="" role="document">
        <div className="">
          <form>
            <div className="modal-header">
              <h4 className="modal-title fs-20">Add Details</h4>
            </div>
            <div className="modal-body">
              <i className="flaticon-cancel-12 close" data-dismiss="modal"></i>
              <div className="add-contact-box">
                <div className="add-contact-content">
                  <div className="form-group mb-3">
                    <label className="text-black font-w500">Date</label>
                    <div className="contact-name">
                      <DatePicker
                        className="form-control w-100"
                        dateFormat={"dd/MM/yyyy"}
                        selected={formData?.date}
                        onChange={(date) => {
                          setFormData({
                            ...formData,
                            date: date,
                          });
                          setErrors({ ...errors, date: "" });
                        }}
                        placeholderText="Select a date"
                        excludeDates={
                          wineryId?.cancelledDates?.map((date) =>
                            parseISO(date)
                          ) || [] // Converting string dates to Date objects
                        }
                        showYearDropdown
                        showMonthDropdown
                        minDate={new Date()}
                      />
                      <span className="validation-text"></span>
                      {errors.date && (
                        <div className="text-danger fs-12">{errors.date}</div>
                      )}
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <label className="text-black font-w500">
                      Choose Time Slots
                    </label>
                    {loader ? (
                      <p className="fs-14 text-black font-w500 text-center">
                        Loading...
                      </p>
                    ) : (
                      <div className="contact-name">
                        {slots.length > 0 ? (
                          <TimeSlot
                            text={"Morning"}
                            slotData={slots}
                            selectedSlot={selectedSlot}
                            setSelectedSlot={setSelectedSlot}
                          />
                        ) : (
                          <p className="fs-12">No Slots Available!</p>
                        )}
                        <span className="validation-text"></span>
                        {errors.slots && (
                          <div className="text-danger fs-12">
                            {errors.slots}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="form-group mb-3">
                    <label className="text-black font-w500">
                    Max amount of Guests per session(max {selectedSlot?.availableGuests || 0})
                    </label>
                    <div className="contact-name">
                      <input
                        type="number"
                        className="form-control"
                        autocomplete="off"
                        name="department"
                        value={formData.guests}
                        min={1}
                        max={selectedSlot?.availableGuests}
                        onChange={(e) => {
                          const value = e.target.value.replace(/[^0-9]/g, ''); 
                          setFormData({
                            ...formData,
                            guests: value,
                          });
                          setErrors({ ...errors, guests: "" }); // Clear error when value changes
                        }}
                        onKeyPress={(e) => {
                          if (!/[0-9]/.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                      />
                      <span className="validation-text"></span>
                      {errors.guests && (
                        <div className="text-danger fs-12">{errors.guests}</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary fs-14 py-2 px-3"
                onClick={onPostData}
              >
                Submit
              </button>
              <button
                type="button"
                onClick={() => onHide()}
                className="btn btn-secondary fs-14 py-2 px-3"
              >
                {" "}
                <i className="flaticon-delete-1"></i> Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
}
