import React, { useEffect, useState } from "react";
import PageTitle from "../layouts/PageTitle";
import { getPolicyTerms } from "../../services/User/UserService";
import parse from "html-react-parser";
export default function CancellationPolicy() {
  const [loader2, setLoader2] = useState(false);
  const [policy, setPolicy] = useState(null);
  const getData = async () => {
    setLoader2(true);
    try {
      const response = await getPolicyTerms();
      console.log(response.data?.data?.policy, "policy");
      setPolicy(response.data?.data?.policy?.cancellationPolicy);
    } catch (error) {
      console.log(error, "error");
    } finally {
      setLoader2(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <div>
      <PageTitle
        activeMenu="Cancellation Policy"
        motherMenu="Cancellation Policy"
      />
      {policy &&  <p className="text-break">{parse(policy)}</p>}
    </div>
  );
}
